
@import "../../bootstrap/variables.less";

@body-bg:					#000;

@text-color:				#fff;
@link-color:				#fff;
@link-hover-color:			#fff;
@link-hover-decoration:		underline;

@font-family-base:			Comic Sans MS;
@font-size-base:			14px;
@line-height-base:			1.25;

@font-size-h1:				24px;
@headings-small-color:		#fff;

@thumb-width:				490px;
@thumb-height:				370px;
@thumb-margin-top:			0px;
@thumb-margin-right:		0px;
@thumb-margin-bottom:		0px;
@thumb-margin-left:			0px;
@thumb-border-width:		1px;
@thumb-border:				1px solid #fff;
@thumb-hover-border:		1px solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#fff;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				20px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				16px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		underline;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	0 0 10px;
@sites-footer-margin:				2px;
@sites-footer-size:					11px;
@sites-footer-color:				#fff;

@parent-alert-padding:		10px;
@parent-alert-margin:		20px auto;
@parent-alert-border:		1px solid #CC0000;
@parent-alert-font-size:	10px;
@parent-alert-color:		#fff;
@parent-alert-bg:			#FE4D01;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/parent_alert.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	text-align: center;
	margin: 2px 0;
	font-size: 20px;
}
.links {
	font-size: 16px;
	margin: 2px 0;
}
#language-switcher {
	position: absolute;
	top: 0;
	right: 10px;
}
.outro {
	text-align: center;
	font-size: 16px;
	margin: 20px;
}
#ad-footer {
	margin: 0;
	img,
	video {
		max-width: 100%;
		border: 3px solid rgb(254, 77, 1);
	}
}